



















































































































import { computed, defineComponent, PropType, reactive, ref, watchEffect } from "@vue/composition-api";
import { createAchievementSuggestion, getAllAchievementSuggestions } from "@/api/achievementService";
import { useNotifier } from "@/providers/notifier";
import AchievementCard from "@/components/achievements/AchievementCard.vue";
import handleError from "@/helpers/errors";
import Learnlink from "@learnlink/interfaces";
import useAsyncData from "@/hooks/useAsyncData";

export default defineComponent({
  name: "CreateAchievementSuggestionDialog",
  components: { AchievementCard },
  props: {
    value: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const _value = ref(props.value);
    watchEffect(() => {
      emit("input", _value);
    });

    const vm = reactive({
      achievement: {
        achievedDescription: "",
        lockedDescription: "",
        title: "",
      } as Learnlink.Achievement.CreateSuggestion,
      loading: false,
    });

    const formRules = {
      achievementDescription: (v: string) => v.length <= 100 || "Beskrivelsen er litt lang - prøv å hold den under 100 karakterer",
    };

    const notify = useNotifier();
    const { isLoading, data: achievementSuggestions, refresh } = useAsyncData(() => getAllAchievementSuggestions());
    const formValidation = computed(() => !!(vm.achievement.achievedDescription &&
      vm.achievement.lockedDescription &&
      vm.achievement.title));

    async function submit() {
      vm.loading = true;
      if (formValidation.value) {
        if (vm.achievement.achievedDescription.length > 100 ||
          vm.achievement.lockedDescription.length > 100) {
          notify({
            title: "Litt lang beskrivelse...",
            message: "Klarer du beskrive achievementen litt kortere?",
            type: "error",
          });
        }
        else {
          try {
            await createAchievementSuggestion(vm.achievement);
            await refresh();
            notify({
              title: "Suksess!",
              message: "Forslag sendt inn! 🥳",
              type: "success",
            });
            vm.achievement.title = "";
            vm.achievement.achievedDescription = "";
            vm.achievement.lockedDescription = "";
            _value.value = false;
          }
          catch (e) {
            handleError(e);
            notify({
              title: "Noe gikk galt! 🤯",
              message: "Klarte ikke å opprette forslag. Prøv igjen, eller kontakt support dersom problemet vedvarer.",
              type: "error",
            });
          }
        }
      }
      else {
        notify({
          title: "Noe mangler! 🤯",
          message: "Husk å fylle ut alle tre feltene.",
          type: "error",
        });
      }
      vm.loading = false;
    }
    return {
      _value,
      achievementSuggestions,
      isLoading,
      formValidation,
      formRules,
      submit,
      vm,
    };
  },
});
