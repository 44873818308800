<template>
  <v-container>
    <v-row
      justify="center"
      class="mb-6 mt-2"
    >
      <h1>
        Achievements
      </h1>
    </v-row>
    <v-row
      justify="center"
    >
      <v-btn
        color="primary"
        class="mt-2 mb-4"
        @click="vm.createAchievementSuggestionDialogVisible = true"
      >
        Har du forslag til achievements?
      </v-btn>
    </v-row>
    <v-row justify="center">
      <v-progress-circular
        v-if="vm.loading"
        color="primary"
        class="ma-16"
        indeterminate
      />
      <v-col
        v-else
        v-for="achievement in achievements"
        :key="achievement.ID"
        cols="6"
      >
        <achievement-card
          :achievement="achievement"
        />
      </v-col>
    </v-row>
    <create-achievement-suggestion-dialog
      v-if="vm.createAchievementSuggestionDialogVisible"
      v-model="vm.createAchievementSuggestionDialogVisible"
    />
  </v-container>
</template>

<script>
import { defineComponent, reactive } from "@vue/composition-api";
import CreateAchievementSuggestionDialog from "@/components/achievements/CreateAchievementSuggestionDialog";
import useAsyncData from "@/hooks/useAsyncData";
import { getAllAchievements } from "@/api/achievementService";
import AchievementCard from "@/components/achievements/AchievementCard";

export default defineComponent({
  name: "AchievementsSeller",
  components: { AchievementCard, CreateAchievementSuggestionDialog },
  setup() {
    const vm = reactive({
      createAchievementSuggestionDialogVisible: false,
    });
    const { isLoading, data: achievements } = useAsyncData(() => getAllAchievements());
    return {
      achievements,
      isLoading,
      vm,
    };
  },
});
</script>
