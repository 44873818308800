
































import { computed, defineComponent, PropType } from "@vue/composition-api";
import { userModule } from "@/store/modules/user";
import AchievementPhoto from "@/components/achievements/AchievementPhoto.vue";
import Learnlink from "@learnlink/interfaces";
import Moment from "@/services/moment";

export default defineComponent({
  name: "AchievementCard",
  components: { AchievementPhoto },
  props: {
    achievement: {
      type: Object as PropType<Learnlink.Achievement.ForPresentation>,
      required: true,
    },
    studentName: {
      type: String as PropType<string>,
      default: "",
    },
  },
  setup(props) {
    const achievementText = computed(() => {
      let text = props.achievement.achieved ? props.achievement.achievedDescription : props.achievement.lockedDescription;
      if (props.studentName) text = text.replace(/du/gi, props.studentName);
      return text;
    });
    const readableAchievedTime = computed(() => props.achievement.achieved ? `Mottatt: ${Moment.unix(props.achievement.achieved).format("L")}` : "Låst");
    const isSeller = userModule.state.user.seller;

    const isAchieved = computed(() => props.achievement.achieved);

    return {
      achievementText,
      isAchieved,
      isSeller,
      readableAchievedTime,
    };
  },
});
