












import { computed, defineComponent, PropType } from "@vue/composition-api";

export default defineComponent({
  name: "AchievementPhoto",
  props: {
    url: {
      type: String as PropType<string>,
      required: true,
    },
    achieved: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    height: {
      type: Number as PropType<number>,
      default: 120,
    },
    width: {
      type: Number as PropType<number>,
      default: 120,
    },
    name: {
      type: String as PropType<string>,
      required: true,
    }
  },
  setup(props) {
    const lockedPhotoURL = "https://firebasestorage.googleapis.com/v0/b/learnlink-prod.appspot.com/o/public%2Flockpad.png?alt=media&token=2a3ffffe-3b58-4239-8183-b336d358b0a1";
    const achievementPhotoPath = computed(() => {
      if (!props.achieved) return lockedPhotoURL;
      return props.url || lockedPhotoURL;
    });
    return {
      achievementPhotoPath,
    };
  },
});
